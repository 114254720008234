import { Routes } from '@angular/router';
import { LoggedInGuard } from '@app/core/guards/logged-in.guard';
import { ProfileResolver } from '@app/core/resolvers/profile.resolver';
import { UserResolver } from '@app/core/resolvers/user.resolver';
import { GroupGuard } from '@app/core/guards/group.guard';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { InitialLoginGuard } from '@app/core/guards/initial-login.guard';
import { SubscriptionTypesResolver } from './core/resolvers/subscription-types.resolver';
import { FaqItemsResolver } from './core/resolvers/faq-items.resolver';
import { PaymentTermsResolver } from './core/resolvers/payment-term.resolver';
import { UserDetailsGuard } from './core/guards/user-details.guard';


export const routes: Routes = [
  {
    path: 'maatwerk',
    loadComponent: () => import('@app/pages/maatwerk-page/maatwerk-page.component').then(c => c.MaatwerkPageComponent),
    data: {
      title: `Maatwerk - KadastraleKaart.com`,
      metatags: {
        description: 'Heeft u specifiek maatwerk nodig? KadastraleKaart.com levert maatwerk voor elke gebruiker en organisatie.'
      }
    }
  },
  {
    path: 'energielabel-aanvragen',
    loadComponent: () => import('@app/pages/juiste-makelaar-page/juiste-makelaar-page.component').then(c => c.JuisteMakelaarPageComponent),
    data: {
      title: `Energielabel aanvragen - KadastraleKaart.com`,
      metatags: {
        description: 'Vraag een energielabel aan bij KadastraleKaart.com.'
      }
    }
  },
  {
    path: 'support',
    loadComponent: () => import('@app/pages/support/support.component').then(c => c.SupportComponent),
    data: {
      title: `Support-pagina - KadastraleKaart.com`,
      metatags: {
        description: 'Op deze pagina kunt u in contact komen met de supportafdeling van KadastraleKaart.com'
      }
    }
  },
  {
    path: 'over-ons',
    loadComponent: () => import('@app/pages/about/about.component').then(c => c.AboutComponent),
    data: {
      title: `Over ons - KadastraleKaart.com`,
      metatags: {
        description: 'Op deze pagina kunt u in contact komen met de supportafdeling van KadastraleKaart.com'
      }
    }
  },
  {
    path: 'kan-niet-worden-weergegeven',
    loadComponent: () => import('@app/pages/unviewable-page/unviewable-page.component').then(c => c.UnviewablePageComponent),
    data: {
      title: `Pagina kan niet worden weergegeven - KadastraleKaart.com`,
      metatags: {
        description: 'Deze pagina kan niet worden weergegeven.'
      }
    }
  },
  {
    path: 'afrekenen',
    loadChildren: () => import('@app/feature/checkout/checkout.module').then(m => m.CheckoutModule),
    data: {
      title: `Uw bestelling afrekenen - KadastraleKaart.com`,
      metatags: {
        description: 'Op deze pagina kunt u uw bestelling afrekenen.'
      }
    }
  },
  {
    path: 'woonplaatsen',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/kennisbank/cities/cities.routes').then(m => m.routes)
  },
  {
    path: 'kadastrale-gemeenten',
    canActivate: [UserDetailsGuard],
    loadChildren: () =>
      import('@app/feature/kennisbank/cadastral-municipality/cadastral-municipality.routes').then(
        m => m.routes
      ),
    data: {
      title: 'Kadastrale gemeenten van Nederland - KadastraleKaart.com ',
      metatags: {
        description: `Snel navigeren naar de kadastrale percelen van alle woonplaatsen en gemeentes in Nederland.`
      }
    }
  },
  {
    path: 'gemeenten',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/kennisbank/municipality/municipality.routes').then(m => m.routes)
  },
  {
    path: 'wijken',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/kennisbank/wijk/wijk.routes').then(m => m.routes)
  },
  {
    path: 'buurten',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/kennisbank/buurt/buurt.routes').then(m => m.routes)
  },
  {
    path: 'handleiding',
    loadChildren: () => import('@app/feature/manual/manual.module').then(m => m.ManualModule)
  },
  {
    path: 'nieuws',
    loadChildren: () => import('@app/feature/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'adres',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/address/address.routes').then(m => m.routes)
  },
  {
    path: 'omgevingswet',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/omgevingswet/omgevingswet.routes').then(m => m.routes)
  },
  {
    path: 'profiel',
    loadChildren: () => import('@app/feature/user/user.module').then(m => m.UserModule),
    data: {
      title: 'Profiel - - KadastraleKaart.com',
      metatags: {
        description: 'Beheer hier uw KadastraleKaar.com accountgegevens'
      }
    },
    canActivate: [LoggedInGuard],
    resolve: {
      user: UserResolver,
      profile: ProfileResolver,
      subscriptionTypes: SubscriptionTypesResolver
    }
  },
  {
    path: 'upgrade',
    resolve: [
      SubscriptionTypesResolver,
      PaymentTermsResolver
    ],
    loadChildren: () => import('@app/feature/upgrade/upgrade.routes').then(m => m.routes)
  },
  {
    path: 'veelgestelde-vragen',
    loadComponent: () => import('@app/pages/faq/faq-page.component').then(c => c.FAQPageComponent),
    data: {
      title: 'Veelgestelde vragen - KadastraleKaart.com',
      structuredData: {
        '@type': 'FAQPage'
      },
      metatags: {
        description: `Welke perceelgegevens worden er getoond op KadastraleKaart.com? En hoe vaak wordt de
                      kadastrale kaart ververst? Vind het antwoord op deze en andere veelgestelde vragen.`
      }
    },
    resolve: {
      faqItems: FaqItemsResolver
    }
  },
  {
    path: 'producten',
    loadChildren: () => import('@app/feature/shop/product-based-shop/product-based-shop.routes').then(m => m.routes)
  },

  {
    path: 'begrippen',
    loadChildren: () => import('@app/feature/kennisbank/definition/definition.module').then(m => m.DefinitionModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('@app/feature/admin/admin.module').then(m => m.AdminModule),
    canActivate: [GroupGuard],
    data: {
      title: 'Admin',
      group: GroupNames.admin,
      redirectTo: '/geen-toegang',
      metatags: {
        description: 'Dit is de adminpagina van Kadastralekaart.com'
      }
    }
  },
  {
    path: 'zoeken',
    redirectTo: 'export'
  },
  {
    path: 'export',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/export/export.routes').then(m => m.routes)
  },
  {
    path: 'gebeurtenissen',
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/change/change.routes').then(m => m.routes)
  },
  {
    path: 'woz',
    loadComponent: () => import('@app/pages/woz/woz.component').then(C => C.WozPageComponent)
  },
  {
    path: 'even-geduld',
    loadComponent: () => import('@app/pages/auth-callback/auth-callback.component').then(c => c.AuthCallbackComponent),
    data: {
      title: 'Even geduld - KadastraleKaart.com',
    },
    canDeactivate: [
      InitialLoginGuard
    ]
  },
  {
    path: 'pagina-niet-gevonden',
    loadComponent: () => import('@app/pages/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent),
    data: {
      title: 'Pagina niet gevonden - KadastraleKaart.com',
      metatags: {
        description: 'Deze pagina bestaat niet op KadastraleKaart.com'
      }
    }
  },
  {
    path: 'geen-toegang',
    loadComponent: () => import('@app/pages/not-authenticated-page/not-authenticated-page.component').then(c => c.NotAuthenticatedPageComponent),
    data: {
      title: 'Toegang geweigerd - KadastraleKaart.com',
      metatags: {
        description: 'Deze pagina is alleen zichtbaar als u bent ingelogd.'
      }
    }
  },
  {
    path: 'open',
    data: {animation: 'shoppingCart'},
    outlet: 'winkelwagen',
    loadComponent: () => import('@app/shared/shopping-cart-redirect/shopping-cart-redirect.component').then(c => c.ShoppingCartRedirectComponent),
  },
  {
    path: '',
    data: {animation: 'niks'},
    canActivate: [UserDetailsGuard],
    loadChildren: () => import('@app/feature/map/map.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'pagina-niet-gevonden'
  }
];
