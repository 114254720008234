import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Renderer2, DoCheck, afterRender, afterNextRender, NgZone } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { filter, take, throttleTime } from 'rxjs/operators';
import { APP_VERSION } from './core/config/config';
import { MapModes } from './core/interfaces/map-modes.enum';
import { AuthNewService } from './core/services/auth-new.service';
import { CustomGoogleEvent, GoogleAnalyticsService } from './core/plugins/google-analytics/google-analytics.service';
import { NotificationService } from './core/services/notification.service';
import { SeoService } from './core/services/seo.service';
import { ShoppingCartService } from './core/services/shopping-cart.service';
import { SetCurrentUrl } from './core/states/models/app.state.model';
import { UpdateMapMode } from './core/states/models/map.state.model';
import { ListProducts } from './core/states/models/products.state.model';
import { AddItemsToCart } from './core/states/models/shopping-cart.state.model';
import { CreateUserParcel, RemoveUserParcel } from './core/states/models/user-parcels.state.model';
import { ChangePaymentTerm, GetUser, UpgradeSubscription } from './core/states/models/user.state.model';
import { CookieConsentModal } from './shared/cookie-consent-modal/cookie-consent-modal.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { DatHuisService } from './core/services/dathuis.service';

import { StructuredDataComponent } from './layout-elements/structured-data/structured-data.component';
import { HeaderComponent } from './layout-elements/header/header.component';
import { AppState } from './core/states/app.state';
import { SurveyService } from './core/services/survey.service';
import { v4 } from 'uuid';
import { UserState } from './core/states/user.state';
import { GoogleAnalyticsComponent } from './core/plugins/google-analytics/google-analytics.component';
import { RollbarComponent } from './core/plugins/rollbar/rollbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [],
  imports: [
    StructuredDataComponent,
    HeaderComponent,
    CookieConsentModal,
    RollbarComponent,
    GoogleAnalyticsComponent,
    RouterOutlet]

})
export class AppComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  public openConsentWindow = this.store.selectSnapshot(AppState.openConsentWindow);
  private subscriptions: Subscription[] = [];
  constructor(
    private ga: GoogleAnalyticsService,
    private auth: AuthNewService,
    private store: Store,
    private actions: Actions,
    private shoppingCartService: ShoppingCartService,
    private seo: SeoService,
    private notificationService: NotificationService,
    private router: Router,
    private surveyService: SurveyService,
    private dathuisService: DatHuisService,
    private renderer: Renderer2,
  ) {
    console.log(`%c version: ${APP_VERSION}`, 'background: #222; color: #bada55');
  }


  ngOnInit() {
    // Adds all SEO data to HTML. SEO data is defined in the router module.
    this.seo.addSeoData();
    this.handleAuthentication();
    // Load these on after authentication check has taken place.
    this.auth.isAuthenticated$.subscribe(async (authState) => {
      this.store.dispatch(new ListProducts());

      if (authState === true) {
        const profile = await this.auth.userProfile$.pipe(
          filter(profile => profile !== null && profile !== undefined),
          take(1)
        ).toPromise();
        this.store.dispatch(new GetUser({ userAuth0Id: profile.sub }));
      }

    });
    // -> https://medium.com/@eugeniyoz/preparing-our-code-for-zoneless-angular-22cb7a738151


    this.store.dispatch(new UpdateMapMode({ mode: MapModes.Identify }));

    this.rememberRoutes();

    // Global action dispatcher listener.
    this.registerActionListeners();
  }



  ngDoCheck(): void {
  }

  ngAfterViewInit() {
    // Needs to be after view init, otherwise the modal isnt loaded into html yet (meaning the display cannot be switched)
    // this.checkUserSurvey();
    // External partners.
    this.dathuisService.enable(this.renderer);
    // this.ga.addScriptTag();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  private rememberRoutes() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.store.dispatch(new SetCurrentUrl({ currentUrl: event.url }))
    });
  }

  /* Typeform user survey */
  private async checkUserSurvey() {
    try {
      const localStorageKey = 'projectontwikkelaars_survey';
      const showPopupAfterMs = 45000;
      const participated = localStorage.getItem(localStorageKey) === 'true';

      if (participated === true) {
        return;
      }

      setTimeout(async () => {

        const { NotificationModalComponent } = await import("./shared/dialogs/notification-modal/notification-modal.component");

        const dialog = await this.notificationService.getDialog();
        const answer = await firstValueFrom(dialog.open(NotificationModalComponent, {
          panelClass: 'custom-dialog',
          data: {
            headerText: 'Bent u projectontwikkelaar en geïnteresseerd in nieuwe locaties?',
            contentText: 'Via onze data kunt u snel en eenvoudig nieuwe locaties vinden voor uw projecten. Benieuwd naar meer informatie? Klik dan op de knop hieronder.',
            actionText: 'Ik ben geïnteresseerd',
            cancelText: 'Nee, bedankt',
            showCancelButton: true
          }
        }).afterClosed());

        localStorage.setItem(localStorageKey, 'true');

        if (answer === false || answer === undefined) {
          return;
        }

        const auth0Id = this.store.selectSnapshot(UserState.user)?.auth0Id;
        const uuid = v4();
        this.surveyService.showSurvey(this.surveyService.availableSurveys.projectontwikkelaars_survey, { user_connection_id: uuid, user_id: auth0Id });
        this.ga.event(CustomGoogleEvent.survey, { type: 'projectontwikkelaars_survey', user_connection_id: uuid, user_id: auth0Id });
      }, showPopupAfterMs);
    } catch (err) {
      console.error(err);
    }

  }



  private async handleAuthentication() {
    const user = await this.auth.userProfile$.pipe(
      filter(user => user !== null && user !== undefined),
      take(1),
    ).toPromise();
  }

  private registerActionListeners() {
    this.actions.pipe(ofActionSuccessful(AddItemsToCart)).subscribe((action: AddItemsToCart) => {

      if (this.dathuisService.enabled$.value === true) {
        // Dathuis shortcut
        return this.router.navigate(['/afrekenen/gegevens']);
      }

      if (action.payload.disableCartOpenTrigger !== true) {
        return this.shoppingCartService.showCart(action.payload.orderItems);
      }

      return null;

    });

    this.actions.pipe(ofActionSuccessful(CreateUserParcel)).subscribe(async (action: CreateUserParcel) => {
      const snackbar = await this.notificationService.getSnackbar();
      snackbar
        .open(`Perceel ${action.payload.parcel.parcelId} opgeslagen.`, 'OK', {
          duration: 4500,
          panelClass: 'styled-snackbar'
        });
    });


    this.actions.pipe(ofActionSuccessful(RemoveUserParcel)).subscribe(async (action: RemoveUserParcel) => {
      const snackbar = await this.notificationService.getSnackbar();
      snackbar.open(`Perceel ${action.payload.parcelId} verwijderd.`, 'OK', {
        duration: 4500,
        panelClass: 'styled-snackbar'
      })
    });

    this.actions.pipe(ofActionSuccessful(ChangePaymentTerm)).subscribe(async (action: ChangePaymentTerm) => {
      const snackbar = await this.notificationService.getSnackbar();
      snackbar.open('Betalingstermijn succesvol gewijzigd.', 'OK', { duration: 4500, panelClass: 'styled-snackbar' });
    });

    this.actions.pipe(ofActionSuccessful(UpgradeSubscription)).subscribe(async (action: ChangePaymentTerm) => {
      const snackbar = await this.notificationService.getSnackbar();
      snackbar.open('Lidmaatschap succesvol aangepast.', 'OK', { panelClass: 'styled-snackbar' });
    });
  }
}
